/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1920px) {

	@media (resolution: 1.25dppx) {
		.hero-text h2 {
			font-size: 180px;
			padding-left: 280px;
		}

		.contact-tab-cont button {
			border-radius: 28px;
			background: rgba(255, 252, 244, 0.15);
			backdrop-filter: blur(6px);
			border: solid 4px rgba(255, 252, 244, 0.0);
			width: 185px;
			margin: 12px 12px;
			padding: 40px 10px;
			height: 240px;
		}

		.contact-tab-cont button span {
			display: block;
			color: #EF7056;
			text-align: center;
			font-family: 'Archivo-Regular', Arial, Helvetica, sans-serif;
			font-size: 28px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-transform: uppercase;
			margin-top: 20px;
		}

		.contact-title p {
			font-size: 24px;
		}

		.contact-title h1 {
			font-size: 58px;
		}
	}
}

@media only screen and (max-width : 1510px) {

	.line-wing::before {
		left: -175px;
		top: -20px;
	}

	.hero-text h1 {
		line-height: 65px;
		font-size: 70px;
	}

	.hero-text {
		padding-left: 30px;
	}

	.banner-card-cont {
		margin: 160px 30px 50px;
	}

	.hero-text h1 span.star:after {
		left: 11px;
		bottom: 21px;
		line-height: 25px;
	}

	.story-bn .hero-text h1 span.star::after {
		bottom: 17px;
	}

	.hero-text h2 {
		font-size: 180px;
		padding-left: 280px;
	}

}

@media only screen and (max-width : 1400px) {

	.line-wing::before {
		left: -175px;
		top: -20px;
	}

	.hero-text h1 {
		line-height: 65px;
		font-size: 70px;
	}

	.hero-text {
		padding-left: 30px;
	}

	.banner-card-cont {
		margin: 160px 30px 50px;
	}

	.hero-text h1 span.star:after {
		left: 11px;
		bottom: 21px;
		line-height: 25px;
	}

	.story-bn .hero-text h1 span.star::after {
		bottom: 17px;
	}

	.card-stack .card-title {
		font-size: 35px;
	}

	.hero-text h2 {
		font-size: 180px;
		padding-left: 300px;
	}

	.diamond-pos img {
		width: 55px;
	}

	.line-5::after {
		bottom: -62px;
		right: 0px;
	}

	.contact-banner .title-sub {
		font-size: 50px;
	}

	.contact-banner .tex-big .sub {
		font-size: 50px;
	}

	.contact-banner .title-sub span.art-text {
		font-size: 100px;
	}

	.contact-banner .title-sub>span {
		font-size: 50px;
	}
}

@media only screen and (max-width : 1290px) {
	.hero-text h1.left-space {
		padding-left: 0;
	}

	.story-stack {
		width: 100%;
	}

	.story-stack>img {
		width: 100%;
	}

	.story-stack .card-title {
		font-size: 32px;
	}

	.hero-text h1 {
		line-height: 65px;
		font-size: 70px;
	}

	.hero-text h1 span.star:after {
		left: 12px;
		bottom: 23px;
		line-height: 25px;
	}

	.hero-text h1.left-space:after {
		line-height: 30px;
		font-size: 210px;
	}

	.contact-tab-cont button {
		width: 230px;
	}
}

@media only screen and (max-width : 1200px) {
	.header {
		padding-left: 0;
		padding-right: 0;
	}

	.hero-text h1 {
		font-size: 45px;
		line-height: 60px;
	}

	.hero-text h1.left-space {
		padding-left: 0px;
	}

	.hero-text h1.left-space::after {
		font-size: 165px;
	}

	.hero-text h1 span.star::after {
		left: 2px;
		bottom: 8px;
		transform: scale(0.6);
	}

	.card-img {
		height: 100%;
	}

	.content-home-1 {
		padding-left: 50px;
		padding-right: 50px;
	}

	.content-pad-1 {
		padding-left: 80px;
	}

	.merge-pic {
		padding-left: 5px;
	}

	.merg-img {
		margin-top: -220px;
	}

	.content-home-3 {
		padding-right: 70px;
		padding-left: 80px;
	}

	.env-arrow::after {
		display: none;
	}

	.contact-banner .title-cont {
		bottom: 10px;
	}

	.article-banner .card-stack .card-img-overlay {
		padding: 60px 60px 0;
	}

	.msg-overlay {
		top: 60px;
	}

	.chat-bub-left h4 {
		line-height: 18px;
	}

	.article-sub-cont .btn {
		margin-bottom: 10px;
	}

	.story-stack {
		width: 100%;
		margin-bottom: 20px;
		max-height: 500px;
	}

	.story-stack video {
		height: 100%;
	}

	.story-stack>img {
		width: 100%;
	}

	.half-curve-cont {
		border-radius: 30% 30% 0 0;
	}

	.line-wing::before {
		display: none;
	}

	.arrow-top-lg-r::after {
		display: none;
	}

	.follow-title {
		Line-height: 35px;
	}

	.numb-box h5 {
		font-size: 95px;
	}

	.muscle-container div>img {
		width: 200px;
	}

	.tex-big {
		font-size: 52px;
	}

	.tex-big .sub {
		font-size: 50px;
	}

	.title-sub .art-text {
		font-size: 105px;
	}

	.amazing-text::before {
		left: -70px;
	}

	.line-sm::after {
		top: 40px;
	}

	.story-bn .hero-text h1 span.star::after {
		bottom: 17px;
	}

	.hero-text {
		padding-top: 100px;
	}

	.start-o {
		transform: scale(0.9);
	}

	.video-container video {
		object-fit: none;
		height: 100%;
	}

	.content-home-1 .sub {
		padding-left: 0;
	}

	.has-arrow-1::after {
		top: 78px;
	}

	.hero-text h2 {
		padding-left: 30px;
		font-size: 140px;
		margin-top: -20px;
	}

	.reviews-cont video {
		height: 100%;
		object-fit: cover;
	}

	.line-5::after {
		bottom: -62px;
		right: 0px;
	}

	.contact-tab-cont button {
		width: 180px;
		height: 240px;
	}

	.form::after {
		right: 0;
		bottom: -190px;
	}

	.contact-banner .title-sub>span {
		font-size: 60px;
	}

	.contact-banner .tex-big .sub {
		font-size: 60px;
	}
}

@media only screen and (max-width : 1024px) {

	.header .header-nav .nav-item {
		margin: 0 10px;
	}

	.hero-text h1.left-space::after {
		font-size: 156px;
	}

	.half-img {
		border-radius: 20px;
		padding: 20px;
		margin: 0;
		width: 100%;
	}

	.half-img img {
		border-radius: 40px;
		width: 100%;
	}

	.footer-banner>img {
		top: 10px;
	}

	.muscle-container div>img {
		width: auto;
	}

	.hero-text {
		padding-top: 190px;
	}

	.banner-arrow {
		bottom: 90px;
	}

	.story-bn-img,
	.story-bn {
		width: auto;
		height: 400px;
	}

	.article-banner .card-stack {
		height: 300px;
	}

	.contact-tab-cont button {
		width: 180px;
		height: 220px;
		margin: 8px;
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.header .header-nav .nav-link {
		color: #fff;
	}

	.offcanvas-title {
		color: #8f8f8f;
	}

	.header .header-nav .nav-link img {
		width: 35px;
	}

	.header .header-nav .nav-item:last-child {
		margin-left: 10px;
	}

	.video-container video {
		height: 100%;
		width: auto;
	}

	.hero-text h1 {
		font-size: 60px;
		line-height: 55px;
	}

	.hero-text h1 span.star::after {
		left: 7px;
		bottom: 18px;
	}

	.hero-text h1.left-space::after {
		line-height: 22px;
		padding-left: 10px;
		font-size: 150px;
		position: static;
		vertical-align: top;
	}

	.banner-card-cont {
		display: block;
		width: 100%;
		left: 0;
		gap: 20px;
		padding: 0;
		margin: 80px 0;
	}

	.banner-card-cont .card {
		width: 100%;
	}

	.banner-card-cont .card:first-child {
		margin: 0 0 30px;
	}

	.banner-card-cont .card-title {
		font-size: 28px;
		line-height: 35px;
	}

	.banner-card-cont::before {
		top: -40px;
		left: -40px;
	}

	.banner-card-cont::after {
		bottom: -40px;
		left: -40px;
	}

	.phone-banner {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.phone-banner .card-title {
		font-size: 30px;
		line-height: 42px;
	}

	.phone-banner .card-body {
		padding: 40px;
	}

	.content-home-1 {
		padding: 40px 20px;
	}

	.content-pad-1 {
		padding: 0;
	}

	.has-arrow-1::after {
		left: 10px;
		top: 79px;
	}

	.merge-pic {
		padding-left: 0;
	}

	.merg-img {
		margin-top: 0;
		top: 120px;
		z-index: 100;
		margin-left: 13px;
	}

	.content-home-2 {
		padding-right: 50px;
		padding-left: 70px;
	}

	.content-home-3 {
		padding-right: 40px;
		padding-left: 40px;
		padding-top: 80px;
	}

	.content-pad-2 {
		padding-left: 50px;
	}

	.card-stack {
		height: 490px;
	}

	.card-stack .card-img {
		height: 100%;
	}

	.card-stack .stack-bt {
		left: 30px;
		bottom: 30px;
	}

	.card-stack .stack-badge {
		left: 30px;
	}

	.yellow-bg-nibble {
		padding: 55px 0 60px;
	}

	.footer-logo {
		margin-bottom: 20px;
	}

	.footer .nav-link {
		line-height: 20px;
		padding: 10px 0;
		font-size: 16px;
	}

	.follow-title {
		font-size: 50px;
		line-height: 30px;
	}

	.footer {
		margin-top: 60px;
	}

	.carousel-container {
		overflow: auto;
	}

	.overlay-content {
		border-radius: 40px;
	}

	.overlay-content video {
		height: 100%;
		width: auto;
	}

	.pos-right-three {
		width: 310px;
	}

	.icon-valid::after {
		display: none;
	}

	.mob-disp-none {
		display: none;
	}

	.env-arrow::after {
		display: none;
	}

	.left-arrow-line::before {
		bottom: -120px;
	}

	.shuffle-txt {
		text-align: center;
	}

	.line-sm-1::before {
		display: none;
	}

	.tex-big .sub {
		line-height: 72px;
	}

	.has-cloud::after {
		left: -40px;
	}

	.shuffle-txt {
		padding: 0 20px;
	}

	.main-title {
		margin-top: 80px;
	}


	.contact-banner-cont .mob-blck {
		display: block !important;
	}

	.contact-banner-cont .flex-grow-1.ms-5 {
		margin: 0 !important;
		padding: 40px;
	}

	.form {
		margin-right: 1.5rem;
		margin-left: 0;
	}

	.form::after {
		right: 20px;
	}

	.contact-banner::after {
		left: 0;
		right: auto;
	}

	.contact-banner img {
		width: 100%;
	}

	.shuffle-txt .art-text.confident::after {
		display: none;
	}

	.peacefully h4 {
		left: 20px;
	}

	.article-banner {
		padding: 20px;
	}

	.article-banner .card-stack .card-img-overlay {
		padding: 50px 20px 0;
	}

	.msg-overlay {
		top: 45px;
		right: 30px;
	}

	.chat-bub-left p {
		font-size: 14px;
	}

	.article-caro-cont {
		padding: 80px 0;
	}

	.stack-box>div:last-child {
		margin-top: 25px;
	}

	.story-card .card-title span {
		margin: 0 0 0 10px !important;
	}

	.half-curve-cont {
		border-radius: 0;
	}

	.line-sm::after {
		display: none;
	}

	.tex-big .sub {
		text-align: center !important;
	}

	.story-stack {
		margin-bottom: 20px;
		max-height: 440px;
	}

	.arrow-top-lg-r::after {
		display: none;
	}

	.line-wing {
		margin: 0;
		padding-right: 0;
	}

	.line-wing::before {
		display: none;
	}

	.circle::after {
		top: 16px;
	}

	.story-card .card-footer {
		margin-top: 15px;
	}

	.half-img {
		border-radius: 20px;
		padding: 20px;
		margin: 0;
	}

	.half-img img {
		border-radius: 40px;
	}

	.footer-banner>img {
		top: 10px;
	}

	.review-bx div .card {
		border-width: 0 0 1px;
		margin-bottom: 20px;
		border-radius: 0;
		padding: 20px 0;
	}

	.mobile-thumb-card .card-header {
		font-size: 20px;
	}

	.story-bn .hero-text h1 span.star::after {
		left: 9px;
		bottom: 18px;
		transform: scale(0.6);
	}

	.story-bn .hero-text {
		padding: 30px 15px;
		padding: 100px 15px;
	}

	.start-o {
		transform: scale(0.9);
	}

	.content-home-1 .sub {
		padding-left: 0;
	}

	.banner-card-cont .card-body {
		padding: 20px 25px;
	}

	.content-home-2 .sub {
		padding-left: 0 !important;
	}

	.orange-big-title {
		font-size: 50px;
	}

	.content-home-1 .title-sub {
		font-size: 33px;
	}

	.story-stack video {
		height: 100%;
		translate: -670px;
	}

	.numb-box {
		margin-bottom: 40px;
	}

	.review-bx .col {
		flex: none;
	}

	.footer-banner {
		padding: 0 15px 40px;
		border-radius: 60em 60em 0px 0;
		text-align: center;
	}

	.footer-banner>img {
		position: static;
		padding-bottom: 20px;
	}

	.pad-lef-15 {
		padding: 0;
	}

	.footer-banner h3 {
		margin-bottom: 30px;
		font-size: 28px;
		line-height: 38px;
	}

	.ipad-tex-cent {
		text-align: center !important;
	}

	.article-banner .card-stack {
		height: 300px;
	}

	.date-cont {
		margin-right: 0;
	}

	.article-sub-title::after {
		position: static;
	}

	.ipad-mar0 {
		margin: 0 !important;
	}

	.contact-banner .tex-big .sub {
		text-align: left !important;
	}

	.contact-tab-cont button {
		width: 200px;
		height: 270px;
	}

	.contact-title h1 {
		font-size: 50px;
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

	/*.overlay-content{border-radius: 40px 40px 0 0;}
	.video-container video{height:100%;width: auto;}
	.banner-card-cont .card-body{padding: 20px 18px;}	
	.pos-top-front{display:none;}
	.center-txt .sub::after {bottom: -35px;}
	.card-stack{height: 480px;}
	.yellow-bg-nibble .col-md-6:last-child{margin-top: 1.5rem !important;}
	
	.follow-title{text-align:left;}
	.footer .col:last-child .justify-content-center{justify-content: left !important;}
	.footer-logo {width: 200px;}
		
		.tex-big .sub{font-size: 60px;line-height: 72px;}
		.shuffle-txt .ms-5{margin-left:0 !important;}
		.tex-big{font-size: 40px;padding: 0 20px;text-align: center;}
		.main-title{margin-top: 50px;}
		.line-sm-1::before {display: none;line-height: 60px;}
		.click-line{bottom: auto;top: 10%;}
		.left-arrow-line::before {display: none;}
		.list-titl img{display:none;}
		.has-arrow-2::after{display:none;}
		.icon-valid::after {display:none;}
		.env-arrow::after {display:none;}
		.mob-disp-none{display:none;}
		.ms-50{margin-left:10px;}
		.list-titl{font-size: 30px;}
		.ipad-blck{display:block !important;}
		.has-arrow-2, .has-cloud{text-align:center;}
		
		
		
		.contact-banner::after {left: 0;right: auto;}
		.contact-banner-cont .flex-grow-1.ms-5{margin: 0 !important;padding:40px;}
		.form{margin:70px 0 0;}
		.form::before, .form::after {display: none;}
		
		.contact-banner .title-sub{text-align: left;}
		
		.shuffle-txt {font-size: 38px;}
		.shuffle-txt .sub { font-size: 60px;}
		.shuffle-txt .art-text{font-size: 120px;}
		.orangebt-line::after {display: none;}
		
		.article-banner{padding: 20px;}
		.article-banner .card-stack .card-img-overlay { padding: 20px 20px 0;}
		.msg-overlay{display: none;}
		.article-sub-cont{padding: 20px 0;}
		.article-sub-title::after {position: static;}
		.article-caro-cont{padding:80px 0;}
		.mob-centr{text-align:center;}
		.mob-mar0{margin-left:0 !important;}
		
		.half-curve-cont{border-radius:0;}
		.line-sm::after {display: none;}
		.story-stack{width: 100%;margin-bottom: 20px;}
		.story-stack .card-title{font-size: 30px;}
		.arrow-top-lg-r::after {display: none;}
		.circle::after {top: 16px;}
		.line-wing{padding: 0;margin: 0;}
		.line-wing::before {display: none;}
		.title-sub{text-align: center;}
		.story-bn .hero-text h1{font-size: 50px;}
		.home-p{margin-bottom:15px;text-align: center !important;font-size: 16px;    line-height: 27px;}
		.pad-lef-15{padding:0;}
		.footer-banner{padding:20px;border-radius: 0;text-align: center;}
		.footer-banner > img{position:static;width: 100%;}
		.art-text.line-3::after {display:none;}
		
		
		.half-img{border-radius: 20px; padding: 20px;  margin: 0;}
		.half-img img{border-radius:40px ;}
		.numb-box h5{font-size: 100px;}
		.review-bx{flex-flow: column;}
		
		
		.numbers-cont{margin-top: 80px;  margin-bottom: 80px;}
		.review-bx div .card{border-width: 0 0 1px ;margin-bottom: 20px;border-radius: 0;    padding-bottom: 20px;}
		.review-bx div .card:last-child{margin-bottom: 0;padding:20px 0;}
		.amazing-text::before{  top: -40px; left: 70px;}
		.line-sm-left{text-align: center;margin-bottom: 30px;}
		.curve-line{text-align: center;}
		.partner-thumb{width: 100%; text-align: center;    margin-left: 0 !important;margin-top: 15px;}
		.object-container .thumb-p{margin-left: 0 !important;text-align: center !important;}	
		.click-here{text-align: center;}
		.click-pos{left: 270px;}
		.click-here-1 .click-pos {  left: 50px;  top: 110px;}
		 .mob-blck {    display: block !important;  }
		  .amazing-text {    margin-top: 60px;  }
		.client-img{text-align:center;}
		.hero-text {    padding: 50px 0;  }
		.hero-text h1 {    font-size: 55px;}
		.hero-text h1 span.star::after {    left: 7px;    bottom: 15px;}
		.story-bn .hero-text h1 span.star::after {left: 5px;    bottom: 12px;  transform: scale(0.6);}
		
		.title-blck{display:block;}
		.start-o{transform: scale(0.7);}
		
		.footer .col:first-child{flex: 1 auto; text-align: center;}
	*/

	.header .header-nav .nav-link {
		color: #fff;
	}

	.offcanvas-title {
		color: #8f8f8f;
	}

	.header .header-nav .nav-link img {
		width: 35px;
	}

	.header .header-nav .nav-item:last-child {
		margin-left: 10px;
	}

	.main-banner {
		padding: 0 15px 46px;
	}

	.overlay-content {
		border-radius: 40px;
		display: block;
	}

	.video-container {
		display: block;
	}

	.video-container video {
		width: 100%;
	}

	.banner-card-cont {
		width: 400px;
		display: block;
		padding: 40px 18px 0;
		margin: 0 0 150px;
	}

	.banner-card-cont .card {
		width: 100%;
	}

	.banner-card-cont .card:first-child {
		margin-bottom: 40px;
	}

	.hero-text {
		padding: 94px 0 0;
		margin-bottom: 120px;
	}

	.hero-text h1 {
		font-size: 60px;
		line-height: 65px;
	}

	.hero-text h1.left-space {
		padding-left: 0;
		text-indent: 50px;
		width: 255px;
	}

	.hero-text h1 span.star::after {
		transform: scale(.5);
		left: -2px;
		bottom: 0;
		top: 2px;
	}

	.hero-text h1.left-space::after {
		line-height: normal;
		padding-left: 0;
		font-size: 140px;
		position: absolute;
		right: auto;
		left: 160px;
		bottom: -90px;
	}

	.phone-banner {
		margin-top: 40px;
		margin-bottom: 90px;
	}

	.banner-card-cont .card-body {
		padding: 20px;
	}

	.phone-banner .card-body {
		padding: 50px 20px 33px;
	}

	.phone-banner .phone-bg {
		height: 370px;
		background-position: 80%;
	}

	.pos-top-front {
		display: none;
	}

	.content-home-1 {
		padding: 20px 13px;
	}

	.content-pad-1 {
		padding-left: 0
	}

	.mob-blck {
		display: block !important;
	}

	.has-arrow-1::after {
		left: 45px;
		top: 60px;
	}

	.pad-tp-18 {
		padding-top: 40px;
	}

	.merg-img::after {}

	.content-home-2 {
		padding-right: 13px;
		padding-left: 13px;
	}

	.title-sub .art-text {
		font-size: 75px;
		line-height: 50px;
	}

	.content-home-3 {
		padding: 40px 13px;
	}

	.content-pad-2 {
		padding: 0;
	}

	.orange-container {
		margin-top: 160px;
	}

	.center-txt .sub::after {
		left: 80px;
		right: auto;
		bottom: -50px;
	}

	.shuffle-txt .art-text.globe::after {
		transform: scale(0.9);
		top: -14px;
		right: -79px;
	}

	.card-img {
		height: 100%;
		width: auto !important;
	}

	.card-img-overlay {
		padding: 40px;
	}

	.card-stack {
		height: 420px;
	}

	.card-stack .stack-badge {
		position: absolute;
	}

	.yellow-bg-nibble {
		padding-bottom: 160px;
	}

	.yellow-bg-nibble .col-md-6:last-child {
		margin-top: 1.5rem !important;
	}

	.mobile-crd-text {
		margin-top: 96px;
	}

	.beyond-container {
		width: 100%;
	}

	.footer {
		margin-top: 100px;
		text-align: center;
	}

	.content-img-rit {
		width: 100%;
		padding: 0;
	}

	.follow-title {
		text-align: center;
	}

	.footer .col:last-child .justify-content-center {
		justify-content: center !important;
	}



	.shuffle-txt .sub {
		padding: 0;
		line-height: 62px;
		font-size: 44px;
	}

	.shuffle-txt .ms-5 {
		margin-left: 0 !important;
	}

	.tex-big {
		font-size: 38px;
		padding: 0 20px;
		text-align: center;
	}

	.main-title {
		margin-top: 50px;
		padding-bottom: 50px;
	}

	.line-sm-1::before {
		display: none;
		line-height: 60px;
	}

	.click-line {
		bottom: auto;
		top: 10%;
	}

	.left-arrow-line::before {
		display: none;
	}

	.list-titl img {
		display: none;
	}

	.has-arrow-2::after {
		display: none;
	}

	.icon-valid::after {
		display: none;
	}

	.env-arrow::after {
		display: none;
	}

	.mob-disp-none {
		display: none;
	}

	.ms-50 {
		margin-left: 10px;
	}

	.list-titl {
		font-size: 30px;
	}

	.contact-ban-bor {
		padding: 40px 40px 40px 0;
	}

	.contact-banner .title-cont {
		position: relative;
		background-size: initial;
	}

	.contact-banner .title-cont .tex-big {
		text-align: left;
		padding: 0 0 30px;
		text-indent: 0;
	}

	.contact-banner .title-sub>span {
		font-size: 50px;
	}

	.contact-banner .title-cont>span {
		margin-left: 0 !important;
	}

	.contact-banner p {
		color: #fff;
		display: block;
	}

	.contact-banner .tex-big .sub {
		font-size: 50px;
		line-height: 62px;
		text-align: left !important;
	}

	.contact-banner::after {
		left: 0;
		right: auto;
	}

	.contact-banner-cont .flex-grow-1.ms-5 {
		margin: 0 !important;
		padding: 20px;
	}

	.form {
		margin: 70px 0 0;
		padding: 0 1.5rem 0 0;
	}

	.form::before,
	.form::after {
		display: block;
		transform: scale(0.6);
		right: 0;
		bottom: -120px;
	}

	.contact-tab-cont button {
		width: 188px;
		height: 260px;
		margin: 8px;
	}



	.shuffle-txt .art-text.confident::after {
		display: none;
	}

	.shuffle-txt {
		font-size: 38px;
	}

	.shuffle-txt .art-text {
		font-size: 75px;
	}

	.orangebt-line::after {
		display: none;
	}

	.mob-blck.ipad-blck {
		width: 100%;
	}

	.article-banner {
		padding: 20px;
	}

	.article-banner .card-stack .card-img-overlay {
		padding: 60px 30px 0;
	}

	.msg-overlay {
		display: block;
		max-width: 260px;
		top: 35px;
	}

	.article-banner .card-stack {
		height: 300px;
	}

	.profile-cont img {
		width: 20px;
		height: 20px;
		margin-right: 0px;
	}

	.profile-cont p {
		font-size: 15px;
	}

	.date-cont {
		font-size: 13px;
		line-height: 20px;
		margin: 0;
	}

	.article-sub-cont {
		padding: 20px 0;
	}

	.article-sub-title::after {
		position: static;
	}

	.mob-mar0 {
		margin-left: 0 !important;
	}

	.article-caro-cont {
		padding: 40px 0;
	}

	.stack-box>div:last-child {
		margin-top: 20px;
	}

	.banner {
		padding: 15px;
	}

	.story-bn {
		height: 300px
	}

	.story-bn img {
		height: 100%;
		max-width: none;
	}

	.story-bn .hero-text h1 {
		font-size: 40px;
		line-height: 50px;
	}

	.story-bn .hero-text h1 span.star::after {
		left: -40px;
		top: 9px;
	}

	.story-bn .hero-text h1.left-space::after {
		font-size: 90px;
		position: static;
		padding-left: 20px;
	}

	.phone-bg-cont {
		background-size: contain;
	}

	.story-card .card-footer {
		margin-top: 20px;
	}

	.half-curve-cont {
		border-radius: 0;
	}

	.line-sm {
		text-align: center !important;
	}

	.line-sm:after {
		display: none;
	}

	.story-stack {
		margin: 0 0 20px;
		width: 100%;
	}

	.arrow-top-lg-r::after {
		display: none;
	}

	.circle::after {
		display: none;
	}

	.line-wing {
		margin-left: 0;
		padding-right: 0;
	}

	.line-wing::before {
		display: none;
	}

	.stack-thumb>img {
		width: 100%;
	}


	.orange-big-title {
		font-size: 44px;
		word-break: break-word;
	}

	.half-img {
		border-radius: 20px;
		padding: 20px;
		margin: 0;
	}

	.half-img img {
		border-radius: 40px;
	}

	.partner-thumb {
		width: 100%;
		text-align: center;
		margin-left: 0 !important;
		margin-top: 15px;
	}

	.object-container {
		margin: 20px 0 0;
	}

	.object-container .thumb-p {
		margin: 0 !important;
		font-size: 20px;
		line-height: 30px;
	}

	.click-here {
		text-align: center;
		display: inline-block;
	}

	.click-pos {
		left: 40px;
		top: 30px;
	}

	.numbers-cont {
		margin-top: 80px;
		margin-bottom: 80px;
	}

	.review-bx .card {
		border-width: 0 0 1px;
		margin-bottom: 20px;
		border-radius: 0;
		padding-bottom: 20px;
	}

	.review-bx .card:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.amazing-text::before {
		top: -60px;
		left: -50px;
	}

	.muscle-container .flex-grow-1.ms-3,
	.muscle-container .flex-shrink-0.ms-3 {
		margin: 0 !important;
	}

	.title-sub .sub {
		font-size: 28px;
		line-height: 38px;
		padding: 0 !important;
	}

	.customer-cont .home-p {
		margin-left: 15px !important;
	}

	.curve-line::after {
		display: none;
	}

	.follow-title {
		margin-top: 25px;
	}

	.amazing-text {
		margin-top: 60px;
		padding-top: 20px;
	}

	.half-video {
		height: 250px;
		width: 200px;
	}

	.title-big {
		padding: 0 0 40px;
		font-size: 44px;
	}

	.waitng-txt {
		top: 80px;
		left: 50px;
		font-size: 52px;
	}

	.card-stack .card-title {
		font-size: 36px;
	}

	.waiting-f-u {
		margin-right: 145px;
		width: 274px;
		float: right;
	}

	.hero-text h1 span.star::after {
		transform: scale(0.8);
		left: -36px;
		top: 15px;
	}

	.title-blck {
		display: block;
	}

	.start-o {
		transform: scale(0.8);
		margin: 0 -5px;
	}

	.mob-text-cent {
		text-align: center !important;
	}

	.banner-arrow {
		bottom: 30px;
	}

	.banner-card-cont .orange-btn {
		display: block;
	}

	.phone-banner .card-text {
		font-size: 52px;
		line-height: 34px;
	}

	.phone-banner .card-title {
		font-size: 28px;
		line-height: 38px;
		margin-bottom: 10px;
	}

	.half-video video {
		height: 100%;
		translate: -60px;
	}

	.title-sub {
		text-align: left;
		text-indent: 10px;
		font-size: 28px;
		line-height: 38px;
		padding-bottom: 30px;
	}

	.content-home-3 .art-text {
		padding-left: 77px;
		display: block;
		text-align: left;
	}

	.pos-right-three {
		width: 400px;
		margin: 0;
		translate: 10px;
	}

	.orange-container {
		padding: 70px 0 100px;
	}

	.white-txt .sub {
		padding-left: 40px;
	}

	.title-big .art-text {
		font-size: 75px;
	}

	.mobile-thumb-card .lines-top::after {
		top: -50px;
		left: -10px;
	}

	.mobile-thumb-card .lines-bottom::after {
		bottom: -50px;
		right: -10px;
	}

	.mobile-thumb-card {
		margin-top: 40px;
	}

	.click-here-1 {
		padding-bottom: 150px;
	}

	.yellow-bg-nibble .justify-content-center {
		display: block !important;
	}

	.yellow-bg-nibble .title-big {
		text-align: left;
	}

	.yellow-bg-nibble .shuffle-txt .sub {
		padding-left: 37px;
	}

	.yellow-bg-nibble .mobile-crd-text {
		margin-top: 44px;
	}

	.black-big-title {
		font-size: 44px;
		margin-bottom: 50px;
	}

	.beyond-container .sm-orange-line::after {
		display: block;
	}

	.beyond-container .title-p {
		text-align: center !important;
	}

	.click-here-1 .click-pos {
		left: -100px;
		top: 180px;
	}

	.footer .col:first-child {
		flex: 1 auto;
	}

	.orange-btn,
	.white-btn {
		font-size: 16px;
	}

	.mobile-crd-text p {
		margin: 50px 0 0;
	}

	.overflow-hide-mob {
		overflow: hidden;
		text-align: right;
	}

	.title-sub.has-arrow-1 {
		padding-bottom: 80px;
	}

	.has-arrow-1 .title-sub .sub {
		text-indent: 112px;
	}

	.has-arrow-1 .sub {
		text-indent: 110px;
	}

	.content-home-2 .sub {
		text-indent: 24px;
	}

	.banner-card-cont::before {
		top: 0;
		left: 0;
	}

	.banner-card-cont::after {
		bottom: -40px;
		left: -13px;
	}

	.merg-img {
		top: -40px;
	}


	.dis-blk-mob {
		display: block;
		margin: 80px 0 !important
	}

	.dis-none-mob {
		display: none;
	}

	.mob-pad-ad {
		padding: 80px 13px !important;
	}

	.line-5::after {
		bottom: -55px;
		transform: scale(.8);
	}

	.hero-text h2 {
		padding-left: 30px;
		font-size: 140px;
		margin-top: -20px;
	}

	.hero-text h2 span {
		display: block;
	}

	.story-bn .hero-text h1.left-space {
		width: auto;
		text-indent: 20px;
	}

	.story-bn .hero-text h1 span.star::after {
		left: -15px;
		bottom: auto;
		right: auto;
		top: 6px;
	}

	.circle-plane {
		width: 90px;
		padding-top: 10px;
	}

	.story-stack video {
		height: auto;
		translate: -670px;
	}

	.video-1 video {
		height: auto;
		translate: -420px;
	}

	.quote::before {
		position: static;
	}

	.circle::after {
		content: url("../assets/circle-line.svg");
		right: auto;
		top: -23px;
		position: absolute;
		display: block;
		transform: scale(.5);
		left: -95px;
	}

	.black-big-title::after {
		top: 20px;
	}

	.numb-box {
		margin-bottom: 40px;
	}

	.orange-p.mt-5.pt-5 {
		margin: 0 !important;
		padding: 0 !important;
	}

	.review-bx .col {
		flex: none;
	}

	.review-bx .card-footer {
		padding: 0px 30px 30px;
	}

	.start-o-1 {
		transform: scale(0.5);
		margin: 0 -10px;
	}

	.ipad-tex-cent {
		text-align: center !important;
	}

	.footer-banner {
		padding: 0 15px 40px;
		border-radius: 60em 60em 0px 0;
		text-align: center;
	}

	.footer-banner>img {
		position: static;
		padding-bottom: 20px;
	}

	.pad-lef-15 {
		padding: 0;
	}

	.footer-banner h3 {
		margin-bottom: 30px;
		font-size: 28px;
		line-height: 38px;
	}

	.amazing-text::after {
		left: 50%;
	}

	.check-container {
		margin-bottom: 40px;
		text-align: center;
	}

	.check-container .form-check-inline {
		margin-right: 15px;
	}

	.check-container .form-check-inline:last-child {
		margin-right: 0;
	}

	.check-container .form-check-input {
		margin-right: 5px;
	}

	.list-titl {
		text-align: left;
	}

	.p-list li {
		line-height: 40px;
	}

	.has-cloud::after {
		left: 90px;
	}

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 576px) {
	.header .header-nav .nav-link {
		color: #fff;
	}

	.offcanvas-title {
		color: #8f8f8f;
	}

	.header .header-nav .nav-link img {
		width: 35px;
	}

	.header .header-nav .nav-item:last-child {
		margin-left: 10px;
	}

	.main-banner {
		padding: 0 15px 46px;
	}

	.overlay-content {
		border-radius: 40px;
		display: block;
	}

	.video-container {
		display: block;
	}

	.video-container video {
		height: 100%;
		width: auto;
		translate: -693px;
	}

	.banner-card-cont {
		width: 100%;
		display: block;
		padding: 40px 18px 0;
		margin: 0 0 150px;
	}

	.banner-card-cont .card {
		width: 100%;
	}

	.banner-card-cont .card:first-child {
		margin-bottom: 40px;
	}

	.hero-text {
		padding: 65px 10px 0;
		margin-bottom: 0;
	}

	.hero-text h1 {
		font-size: 60px;
		line-height: 65px;
	}

	.hero-text h1.left-space {
		padding-left: 0;
		text-indent: 50px;
	}

	.hero-text h1 span.star::after {
		transform: scale(.5);
		left: -2px;
		bottom: 0;
		top: 2px;
	}

	.hero-text h1.left-space::after {
		line-height: normal;
		padding-left: 0;
		font-size: 140px;
		position: absolute;
		right: auto;
		left: 160px;
		bottom: -90px;
	}

	.phone-banner {
		margin-top: 40px;
		margin-bottom: 90px;
	}

	.banner-card-cont .card-body {
		padding: 20px;
	}

	.phone-banner .card-body {
		padding: 50px 20px 33px;
	}

	.phone-banner .phone-bg {
		height: 370px;
		background-position: 80%;
	}

	.pos-top-front {
		display: none;
	}

	.content-home-1 {
		padding: 20px 13px;
	}

	.content-pad-1 {
		padding-left: 0
	}

	.mob-blck {
		display: block !important;
	}

	.has-arrow-1::after {
		left: 45px;
		top: 60px;
	}

	.pad-tp-18 {
		padding-top: 40px;
	}

	.merg-img::after {}

	.content-home-2 {
		padding-right: 13px;
		padding-left: 13px;
	}

	.title-sub .art-text {
		font-size: 75px;
		line-height: 20px;
	}

	.content-home-3 {
		padding: 40px 13px;
	}

	.content-pad-2 {
		padding: 0;
	}

	.orange-container {
		margin-top: 160px;
	}

	.center-txt .sub::after {
		left: 80px;
		right: auto;
	}

	.shuffle-txt .art-text.globe::after {
		transform: scale(0.9);
		top: -14px;
		right: -79px;
	}

	.card-img {
		height: 100%;
		width: auto !important;
	}

	.card-img-overlay {
		padding: 40px;
	}

	.card-stack {
		height: 420px;
	}

	.card-stack .stack-badge {
		position: absolute;
	}

	.yellow-bg-nibble {
		padding-bottom: 160px;
	}

	.yellow-bg-nibble .col-md-6:last-child {
		margin-top: 1.5rem !important;
	}

	.mobile-crd-text {
		margin-top: 96px;
	}

	.beyond-container {
		width: 100%;
	}

	.footer {
		margin-top: 100px;
		text-align: left;
	}

	.content-img-rit {
		width: 100%;
		padding: 0;
	}

	.follow-title {
		text-align: center;
	}

	.footer .col:last-child .justify-content-center {
		justify-content: center !important;
	}



	.shuffle-txt .sub {
		padding: 0;
		line-height: 62px;
		font-size: 44px;
	}

	.shuffle-txt .ms-5 {
		margin-left: 0 !important;
	}

	.tex-big {
		font-size: 38px;
		padding: 0 20px;
		text-align: center;
	}

	.main-title {
		margin-top: 50px;
		padding-bottom: 50px;
	}

	.line-sm-1::before {
		display: none;
		line-height: 60px;
	}

	.click-line {
		bottom: auto;
		top: 10%;
	}

	.left-arrow-line::before {
		display: none;
	}

	.list-titl img {
		display: none;
	}

	.has-arrow-2::after {
		display: none;
	}

	.icon-valid::after {
		display: none;
	}

	.env-arrow::after {
		display: none;
	}

	.mob-disp-none {
		display: none;
	}

	.ms-50 {
		margin-left: 10px;
	}


	.contact-ban-bor {
		padding: 10px 10px 10px 0;
	}

	.contact-banner .title-cont {
		position: relative;
		background-size: initial;
		padding: 40px 20px;
	}

	.contact-banner .title-cont .tex-big {
		text-align: left;
		padding: 0 0 15px;
	}

	.contact-banner .title-cont span {
		margin-left: 0 !important;
		font-size: 35px;
	}

	.contact-banner .title-cont span.art-text {
		font-size: 70px;
	}

	.contact-banner p {
		color: #fff;
		font-size: 22px;
		line-height: 28px;
	}

	.contact-banner .tex-big .sub {
		font-size: 35px;
		line-height: 52px;
		text-align: left !important;
	}

	.contact-banner::after {
		left: 0;
		right: auto;
	}

	.contact-banner-cont .flex-grow-1.ms-5 {
		margin: 0 !important;
		padding: 20px;
	}

	.form {
		margin: 70px 0 0;
		padding: 0 1.5rem 0 0;
	}

	.form::before,
	.form::after {
		display: block;
		transform: scale(0.6);
		right: -50px;
		bottom: -120px;
	}

	.contact-tab-cont button {
		width: 100%;
		margin: 8px 0;
	}

	.shuffle-txt .art-text.confident::after {
		display: none;
	}

	.shuffle-txt {
		font-size: 38px;
	}

	.shuffle-txt .art-text {
		font-size: 75px;
	}

	.orangebt-line::after {
		display: none;
	}

	.article-banner {
		padding: 20px 13px;
	}

	.article-banner .card-stack .card-img-overlay {
		padding: 30px 30px 0;
	}

	.msg-overlay {
		display: block;
		position: static;
		margin-top: -40px;
		max-width: 100%;
	}

	.article-banner .card-stack {
		height: 300px;
	}

	.profile-cont img {
		width: 20px;
		height: 20px;
		margin-right: 0px;
	}

	.profile-cont p {
		font-size: 15px;
	}

	.date-cont {
		font-size: 13px;
		line-height: 20px;
		margin: 0;
	}

	.article-sub-cont {
		padding: 20px 0;
	}

	.article-sub-title::after {
		position: static;
	}

	.mob-mar0 {
		margin-left: 0 !important;
	}

	.article-caro-cont {
		padding: 40px 0;
	}

	.article-sub-title-1::after {
		padding-left: 0;
		right: 0px;
		top: -30px;
	}

	.stack-box>div:last-child {
		margin-top: 20px;
	}

	.banner {
		padding: 15px;
	}

	.story-bn {
		height: 300px
	}

	.story-bn img {
		height: 100%;
		max-width: none;
	}

	.story-bn .hero-text h1 {
		font-size: 40px;
	}

	.story-bn .hero-text h1.left-space::after {
		font-size: 90px;
		left: 0;
		bottom: -20px;
	}

	.phone-bg-cont {
		background-size: contain;
	}

	.story-card .card-footer {
		margin-top: 20px;
	}

	.half-curve-cont {
		border-radius: 0;
	}

	.line-sm {
		text-align: center !important;
	}

	.line-sm:after {
		display: none;
	}

	.story-stack {
		margin: 0 0 20px;
		width: 100%;
		max-height: 440px;
	}

	.arrow-top-lg-r::after {
		display: none;
	}

	.line-wing {
		margin-left: 0;
		padding-right: 0;
	}

	.line-wing::before {
		display: none;
	}

	.stack-thumb>img {
		width: 100%;
	}


	.orange-big-title {
		font-size: 43px;
		word-break: break-word;
	}

	.half-img {
		border-radius: 20px;
		padding: 20px;
		margin: 0;
	}

	.half-img img {
		border-radius: 40px;
	}

	.partner-thumb {
		width: 100%;
		text-align: center;
		margin-left: 0 !important;
		margin-top: 15px;
	}

	.object-container {
		margin: 20px 0 0;
	}

	.object-container .thumb-p {
		margin: 0 !important;
		font-size: 20px;
		line-height: 30px;
	}

	.click-here {
		text-align: center;
	}

	.click-pos {
		right: 50%;
		top: 70px;
		left: auto;
	}

	.numbers-cont {
		margin-top: 80px;
		margin-bottom: 80px;
	}

	.review-bx .card {
		border-width: 0 0 1px;
		margin-bottom: 20px;
		border-radius: 0;
		padding-bottom: 20px;
	}

	.review-bx .card:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.amazing-text::before {
		top: -60px;
		left: -110px;
	}

	.muscle-container .flex-grow-1.ms-3,
	.muscle-container .flex-shrink-0.ms-3 {
		margin: 0 !important;
	}

	.title-sub .sub {
		font-size: 28px;
		line-height: 38px;
		padding: 0 !important;
	}

	.customer-cont .home-p {
		margin-left: 15px !important;
	}

	.curve-line::after {
		display: none;
	}

	.follow-title {
		margin-top: 0;
	}

	.amazing-text {
		margin-top: 60px;
		padding-top: 20px;
	}

	.half-video {
		height: 230px;
		width: 118px;
	}

	.title-big {
		padding: 0 0 40px;
		font-size: 44px;
	}

	.waitng-txt {
		top: 80px;
		left: 50px;
		font-size: 52px;
	}

	.card-stack .card-title {
		font-size: 36px;
	}

	.waiting-f-u {
		margin-right: 13px;
		width: 274px;
		float: right;
	}

	.hero-text h1 span.star::after {
		transform: scale(0.8);
		left: -36px;
		top: 15px;
	}

	.title-blck {
		display: block;
	}

	.start-o {
		transform: scale(0.8);
		margin: 0 -5px;
	}

	.mob-text-cent {
		text-align: center !important;
	}

	.banner-arrow {
		bottom: 30px;
	}

	.banner-card-cont .orange-btn {
		display: block;
	}

	.phone-banner .card-text {
		font-size: 52px;
		line-height: 34px;
	}

	.phone-banner .card-title {
		font-size: 28px;
		line-height: 38px;
		margin-bottom: 10px;
	}

	.half-video video {
		height: 100%;
		translate: -60px;
	}

	.title-sub {
		text-align: left;
		text-indent: 10px;
		font-size: 28px;
		line-height: 38px;
		padding-bottom: 30px;
	}

	.content-home-3 .art-text {
		padding-left: 50px;
	}

	.pos-right-three {
		width: 100%;
		margin: 0;
		translate: 10px;
	}

	.orange-container {
		padding: 70px 0 100px;
	}

	.white-txt .sub {
		padding-left: 40px;
	}

	.title-big .art-text {
		font-size: 75px;
	}

	.mobile-thumb-card .lines-top::after {
		top: -50px;
		left: -10px;
	}

	.mobile-thumb-card .lines-bottom::after {
		bottom: -50px;
		right: -10px;
	}

	.mobile-thumb-card {
		margin-top: 40px;
	}

	.click-here-1 {
		padding-bottom: 150px;
	}

	.yellow-bg-nibble .justify-content-center {
		display: block !important;
	}

	.yellow-bg-nibble .title-big {
		text-align: left;
	}

	.yellow-bg-nibble .shuffle-txt .sub {
		padding-left: 37px;
	}

	.yellow-bg-nibble .mobile-crd-text {
		margin-top: 44px;
	}

	.black-big-title {
		font-size: 44px;
		margin-bottom: 50px;
	}

	.beyond-container .sm-orange-line::after {
		display: block;
	}

	.beyond-container .title-p {
		text-align: center !important;
	}

	.click-here-1 .click-pos {
		left: -100px;
		top: 180px;
	}

	.footer .col:first-child {
		flex: 1 auto;
	}

	.orange-btn,
	.white-btn {
		font-size: 16px;
	}

	.mobile-crd-text p {
		margin: 50px 0 0;
	}

	.overflow-hide-mob {
		overflow: hidden;
	}

	.title-sub.has-arrow-1 {
		padding-bottom: 80px;
	}

	.has-arrow-1 .sub {
		text-indent: 110px;
	}

	.content-home-2 .sub {
		text-indent: 24px;
	}

	.merg-img {
		top: -40px;
	}

	.hero-text h2 {
		font-size: 140px;
		padding-left: 50px;
		line-height: 91px;
	}

	.hero-text h2 span {
		padding-left: 57px;
	}

	.story-bn .hero-text h1.left-space {
		width: auto;
		text-indent: 20px;
	}

	.story-bn .hero-text h1 span.star::after {
		left: -15px;
		bottom: auto;
		right: auto;
		top: 6px;
	}

	.circle::after {
		content: url("../assets/circle-line.svg");
		right: auto;
		top: -23px;
		position: absolute;
		display: block;
		transform: scale(.5);
		left: -95px;
	}

	.quote::before {
		position: static;
	}

	.story-bn .hero-text {
		padding: 35px 15px;
	}

	.black-big-title::after {
		transform: scale(.6);
		left: -30px;
		right: auto;
		bottom: -150px;
	}

	.numb-box {
		margin-bottom: 60px;
	}

	.orange-p.mt-5.pt-5 {
		margin: 0 !important;
		padding: 0 !important;
	}

	.review-bx .card-footer {
		padding: 0px 30px 30px;
	}

	.muscle-container {
		padding: 0;
	}

	.start-o-1 {
		transform: scale(0.5);
		margin: 0 -10px;
	}

	.client-img {
		margin-bottom: 20px;
	}

	.amazing-text div {
		width: 342px;
	}

	.line-sm-left::after {
		left: -10px;
	}

	.page-not-found {
		text-align: center;
		margin-top: 20%;
	}

	.footer-banner {
		padding: 0 15px 40px;
		border-radius: 60em 60em 0px 0;
		text-align: center;
	}

	.footer-banner>img {
		width: 100%;
		position: static;
		padding-bottom: 20px;
	}

	.pad-lef-15 {
		padding: 0;
	}

	.footer-banner h3 {
		margin-bottom: 30px;
		font-size: 28px;
		line-height: 38px;
	}

	.art-text.line-3::after {
		transform: scale(0.7);
		left: -60px;
	}

	.amazing-text video {
		width: auto;
		height: 100%;
		translate: -80px;
	}

	.source-txt {
		position: static;
		text-align: center;
	}

	.reviews-cont {
		margin-bottom: 50px;
	}

	.shuffle-txt .art-text.confident {
		padding: 0;
		font-size: 70px;
	}

	.mob-mar-30 {
		margin-top: 30px;
	}

	.second-footer {
		font-size: 15px;
	}

	.footer-logo {
		margin-bottom: 60px;
	}

	.check-container {
		overflow: hidden;
		text-align: left;
	}

	.check-container .form-check-inline {
		margin-right: 0;
		width: 50%;
		float: left;
	}

	.check-container .form-check-input {
		margin-right: 15px;
	}

	.orange-badge-outline {
		display: inline-block;
		margin-bottom: 10px;
	}

	.article-sub-title-1 {
		padding: 0 13px;
	}

	.list-titl {
		text-align: left;
		font-size: 28px;
	}

	.p-list li {
		line-height: 40px;
	}

	.has-cloud::after {
		left: -10px;
		bottom: -90px;
	}

	.form .card {
		padding: 40px 20px;
	}


}