@font-face {
    font-family: Archivo;
    src: url(../fonts/Archivo-Regular.ttf);
}

@font-face {
    font-family: Archivo-Thin;
    src: url(../fonts/Archivo-Thin.ttf);
}

@font-face {
    font-family: Archivo-Light;
    src: url(../fonts/Archivo-Light.ttf);
}

@font-face {
    font-family: Archivo-Medium;
    src: url(../fonts/Archivo-Medium.ttf);
}

@font-face {
    font-family: Archivo-SemiBold;
    src: url(../fonts/Archivo-SemiBold.ttf);
}

@font-face {
    font-family: Archivo-Bold;
    src: url(../fonts/Archivo-Bold.ttf);
}

@font-face {
    font-family: Autumn-Wind;
    src: url(../fonts/Autumn-Wind.ttf);
}


* {
    box-sizing: border-box;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #161616;
    font-weight: normal;
    background: #FFFCF4;
    overflow-x: hidden;
}

.header {
    padding: 26px 70px 20px;
    background: #FDF3DC;
}

.header .header-nav .nav-item {
    margin: 0 22px;
}

.header .header-nav .nav-item:last-child {
    margin-right: 0;
}

.header .header-nav .nav-link {
    font-size: 20px;
    color: #303838;
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
    position: relative;
    padding-bottom: 15px;
}

.header .header-nav .nav-link img {
    padding-right: 15px;
}

.header .header-nav .nav-link.active:after {
    transform: skewy(-2deg);
    transform-origin: bottom left;
    border-bottom: solid 3px #EF7056;
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.header .dropdown-toggle::after {
    border: 0;
    content: url("../assets/icons/arrow-down.svg");
    vertical-align: top;
}

.navbar-toggler {
    border: none;
}

.main-banner {
    border-radius: 0px 0px 40px 40px;
    background: #FDF3DC;
    padding: 0 40px 46px;
}

.video-container {
    position: relative;
    width: 100%;
    display: flex;
    border-radius: 40px;
    overflow: hidden;
}

.video-container video {
    /*object-fit: none;*/
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
}

.overlay-content {
    position: relative;
    display: flex;
    width: 100%;
}

.overlay-content:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 40px;
    background: linear-gradient(-77deg, rgba(48, 56, 56, 0.20) 3.75%, rgba(48, 56, 56, 0.46) 45.89%, #303838 93.92%);
    height: 100%;
    width: 100%;
    z-index: 10;
}

.story-bn .overlay-content {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 40px;
    background: linear-gradient(-77deg, rgba(48, 56, 56, 0.20) 3.75%, rgba(48, 56, 56, 0.46) 45.89%, #303838 93.92%);
    height: 100%;
    width: 100%;
    z-index: 10;
}

.hero-text {
    position: relative;
    z-index: 10;
    padding-left: 50px;
    padding-top: 220px;
}

.hero-text h1 {
    font-size: 90px;
    color: #FDF3DC;
    font-family: 'Archivo-Thin', Arial, Helvetica;
    font-weight: normal;
    line-height: 85px;
}

.hero-text h1.left-space {
    padding-left: 80px;
}

.hero-text h1 span.star {
    position: relative;
    color: #EF7056;
}

.hero-text h1 span.star:after {
    content: url("../assets/icons/star.svg");
    position: absolute;
    left: 20px;
    bottom: 20px;
}

/*.hero-text h1.left-space:after{content:"us";font-family:'Autumn-Wind',Arial, Helvetica, sans-serif;font-size: 230px;font-weight:500;color: #EF7056;position: absolute;line-height: 37px;  padding-left: 30px;}*/
.hero-text h2 {
    content: "us";
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 230px;
    font-weight: 500;
    color: #EF7056;
    line-height: 91px;
    padding-left: 400px;
}

.banner-card-cont {
    position: relative;
    width: 400px;
    z-index: 20;
    margin: 160px 50px 50px;
}

.banner-card-cont:before {
    content: url("../assets/icons/icon-lines-top.svg");
    position: absolute;
    top: -30px;
    left: -60px;
}

.banner-card-cont:after {
    content: url("../assets/icons/icon-lines-bottom.svg");
    position: absolute;
    bottom: -30px;
    left: -60px;
}

.banner-card-cont .card {
    border-radius: 40px;
    background: #FDF7E9;
}

.banner-card-cont .card:first-child {
    margin-bottom: 40px;
}

.banner-card-cont .card-body {
    padding: 20px 35px;
}

.banner-card-cont .card-title {
    font-family: 'Archivo-Thin', Arial, Helvetica;
    font-size: 35px;
    font-style: normal;
    font-weight: 250;
    line-height: 42px;
    margin-bottom: 18px;
}

.orange-btn {
    padding: 14px 30px;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    border-radius: 40px;
    background: #EF7056;
    border: none;
    color: #FDF3DC;
}

.main-content {
    margin: 0;
}

.main-title {
    margin-top: 150px;
    text-align: center;
    padding-bottom: 90px;
}

.orange-big-title {
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    font-size: 60px;
    font-style: normal;
    color: #EF7056;
    text-align: center;
    word-break: break-all;
}

.black-big-title {
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    font-size: 60px;
    font-style: normal;
    color: #303838;
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 0 30px;
}

.black-big-title:after {
    content: url("../assets/line-orange.svg");
    left: 0;
    top: 40px;
    position: absolute;
}

.pad-bt-10 {
    padding-bottom: 10px;
}

.half-img {
    border-radius: 0px 60px 60px 0px;
    background: #FDF3DC;
    padding: 20px 25px 20px 0;
    display: inline-block;
    margin-left: -12px;
}

.half-img img {
    filter: drop-shadow(1px 3px 7px rgba(0, 0, 0, 0.30));
    border-radius: 0px 40px 40px 0;
}

.object-container {
    margin-top: 90px;
}

.object-container .d-flex {
    margin-bottom: 0;
}

.object-container .d-flex:first-child {
    margin-bottom: 60px;
}

.thumb-round {
    border-radius: 50%;
}

.thumb-title {
    font-size: 18px;
    line-height: 25px;
    color: #303838;
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 15px;
}

.thumb-title span {
    display: block;
}

.partner-thumb {
    width: 170px;
}

.thumb-p {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 34px;
}

.click-here {
    position: relative;
    padding-bottom: 75px;
}

.click-pos {
    font-size: 64px;
    line-height: 41px;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    transform: translateX(180px) rotate(3deg);
    display: inline-block;
    width: 170px;
    color: #EF7056;
    position: absolute;
    left: 120px;
    top: 50px;
    font-weight: 500;
}

.click-pos img {
    position: absolute;
    left: -90px;
    bottom: 20px;
}

.phone-banner {
    margin-top: 100px;
    margin-bottom: 160px;
    border-radius: 40px;
    background: #FDF3DC;
    box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.30);
    border: none;
    overflow: hidden;
}

.phone-banner .card-body {
    padding: 110px 45px 110px 85px;
}

.phone-banner .card-title {
    color: #303838;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    font-size: 35px;
    line-height: 42px;
    font-weight: normal;
}

.phone-banner .card-text {
    color: #EF7056;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 64px;
    line-height: 41px;
    font-weight: 500;
}

.phone-banner .phone-bg {
    background-image: url("https://storage.googleapis.com/mhm-medias/store-front/Iphone14GhostedMockup02_MicroVolume-6%201.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.title-sub {
    color: #303838;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    font-size: 35px;
    line-height: 42px;
    text-transform: uppercase;
    padding-bottom: 45px;
    margin-bottom: 0;
}

.title-sub .art-text {
    color: #EF7056;
    text-align: center;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 110px;
    line-height: 54px;
    vertical-align: middle;
    padding-left: 10px;
    font-weight: 500;
    text-transform: lowercase;
}

.title-sub .sub {
    color: #303838;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    ;
    font-size: 35px;
    line-height: 42px;
    padding-left: 15%;
}

.has-arrow-1 {
    position: relative;
    padding-bottom: 55px;
}

.has-arrow-1:after {
    content: url("../assets/icons/arrow-curve-2.svg");
    left: 80px;
    top: 70px;
    position: absolute;
}

.content-home-1 {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
}

.home-p {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
}

.content-pad-1 {
    padding-left: 170px;
}

.pad-tp-18 {
    padding-top: 120px;
}

.content-home-2 {
    padding-right: 150px;
    padding-left: 140px;
    padding-top: 60px;
}

.merge-pic {
    padding-left: 105px;
}

.merg-img {
    margin-top: -130px;
    width: 300px;
    position: relative;
    z-index: -1;
}

.merg-img img {
    width: 100%;
}

.merg-img:after {
    content: url("../assets/icons/icon-lines-bottom-right.svg");
    position: absolute;
    bottom: -60px;
    right: -50px;
}

.pos-top-front {
    position: relative;
    z-index: 10;
    width: 180px;
}

.content-img-rit {
    width: 300px;
    padding-right: 20px;
    filter: drop-shadow(1px 3px 7px rgba(0, 0, 0, 0.30));
    border-radius: 40px;
}

.content-home-3 {
    padding-right: 100px;
    padding-left: 200px;
    padding-top: 80px;
}

.content-home-3 .title-sub .sub {
    padding-left: 0;
}

.pos-right-three {
    width: 400px;
}

.content-pad-2 {
    padding-left: 100px;
}

.numbers-cont {
    margin-top: 130px;
    margin-bottom: 40px;
}

.numb-box {
    text-align: center;
}

.numb-box h5 {
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    text-align: center;
    color: #EF7056;
    font-size: 110px;
    font-weight: 500;
}

.numb-box p {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 0;
}


.footer {
    margin-top: 200px;
    padding: 80px 0 25px;
    background: #FDF3DC;
}

.follow-title {
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    text-align: center;
    color: #EF7056;
    font-size: 64px;
    font-weight: 500;
}

.second-footer {
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #EF7056;
    text-align: center;
    padding-top: 60px;
    margin-bottom: 0;
}

.footer .nav-link {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
}

.reviews-cont {
    width: 100%;
    padding: 120px 0;
    height: auto;
    overflow: hidden;
    position: relative;
}

.reviews-cont video {
    /*object-fit:none;*/
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
}

.review-bx {
    border-radius: 40px;
    padding: 50px 0;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
}

.review-bx div .card {
    border-width: 0 2px 0 0;
    border-color: #EF7056;
    border-radius: 0;
}

.review-bx div:last-child .card {
    border-width: 0;
}

.review-bx .card-body {
    padding: 0 20px 25px;
}

.review-bx .card-footer {
    padding: 0px 30px;
    border: 0;
    background: none;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #303838;
}

.review-bx .card-footer small {
    display: block;
}

.review-bx .card-text {
    font-size: 24px;
    line-height: 34px;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    color: #303838;
    position: relative;
    padding: 10px;
}

.review-bx .card-text:before {
    content: url("../assets/icons/quote-left.svg");
    position: absolute;
    left: -10px;
    top: -10px;
}

.review-bx .card-text:after {
    content: url("../assets/icons/quote-right.svg");
    position: absolute;
    bottom: 0px;
    padding-left: 5px;
}


.orange-container {
    background: #EF7056;
    padding: 50px 0;
    margin-top: 185px;
}

.white-txt {
    color: #FDF3DC !important;
}

.white-txt .sub {
    color: #FDF3DC;
    padding-left: 70px;
    text-transform: lowercase;
}

.white-txt .art-text {
    color: #FDF3DC !important;
    vertical-align: top !important;
}

.carousel-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding: 0;
}

.thumb {
    position: relative;
    margin-right: 30px;
    margin-bottom: 0;
}

.thumb:last-child {
    margin-right: 0px;
}

.thumb>img {
    border-radius: 40px;
    overflow: hidden;
    margin: 0;
}

.thumb .figure-caption {
    color: #FDF3DC;
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.count-numb {
    color: #FDF3DC;
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    right: 30px;
    top: 30px;
    position: absolute;
}

.count-numb img {
    padding-left: 5px;
}

.thumb:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-image: linear-gradient(149deg, rgba(48, 56, 56, 0.90) 17.74%, rgba(48, 56, 56, 0.00) 42.61%, rgba(48, 56, 56, 0.00) 67.87%, rgba(48, 56, 56, 0.90) 93.53%);
    border-radius: 40px;
    overflow: hidden;
}

.white-btn {
    padding: 14px 30px;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    border-radius: 40px;
    background: #FDF3DC;
    border: none;
    color: #EF7056;
}

.btn-with-star {
    position: relative;
}

.btn-with-star:after {
    content: url("../assets/icons/icon-stars-1.svg");
    position: absolute;
    top: 0px;
    right: -60px;
}

.shuffle-txt {
    text-align: left;
}

.shuffle-txt .sub {
    padding-left: 40px;
    text-transform: lowercase;
}

.shuffle-txt .art-text.globe {
    position: relative;
}

.shuffle-txt .art-text.globe:after {
    content: url("../assets/icons/icon-world.svg");
    position: absolute;
    top: 0px;
    right: -85px;
}

.title-p {
    font-family: 'Archivo-Light', Arial, Helvetica;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

.check-container .form-check-input:checked {
    background-color: #EF7056;
    border-color: #EF7056;
}

.check-container .form-check-input {
    border-color: #EF7056;
    border-width: 2px;
    width: 28px;
    height: 28px;
    border-radius: 8px;
    margin-right: 15px;
    background: #FFFCF4;
}

.check-container .form-check-inline {
    margin-right: 45px;
}

.check-container .form-check-label {
    line-height: 35px;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 18px;
}

.check-container .form-check-input:checked[type="checkbox"] {
    background-image: url("../assets/icons/icon-cross.svg");
    background-size: 18px;
}

.check-container .form-check-input:focus {
    box-shadow: none;
}

.card-stack {
    border-radius: 40px;
    overflow: hidden;
    height: 400px;
    border: 0;
}

.card-stack>img {
    height: 100%;
}

.card-stack:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(75deg, #303838 13.08%, rgba(48, 56, 56, 0.00) 96.72%);
    border-radius: 40px;
    overflow: hidden;
}

.card-stack .card-text {
    color: #FFFCF4;
    font-family: 'Archivo-Light', Arial, Helvetica;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
}

.card-stack .card-title {
    color: #FFFCF4;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 40px;
    line-height: normal;
    margin-bottom: 10px;
}

.btn-orange-link {
    color: #EF7056;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 19px;
    line-height: normal;
    text-decoration-line: underline;
}

.card-stack .stack-bt {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: calc(100% - 60px);
}

.card-stack .stack-badge {
    border-radius: 40px;
    background: #FDF3DC;
    color: #EF7056;
    text-align: center;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 18px;
    line-height: 20px;
    padding: 4px 10px;
    position: absolute;
    top: 30px;
    left: 30px;
}

.article-banner .card-stack {
    height: auto;
}

.check-container {
    margin-bottom: 40px;
}

.carousel-btn {
    margin-top: 130px;
}

.carousel-btn img {
    cursor: pointer;
}

.mobile-thumb-cont {
    margin: 100px 0;
}

.center-txt {
    text-align: center;
    padding-bottom: 65px;
}

.center-txt .sub {
    padding-left: 0;
    position: relative;
    text-transform: none;
}

.center-txt .sub:after {
    content: url("../assets/line-orange-1.svg");
    right: -30px;
    bottom: -35px;
    position: absolute;
}

.mobile-thumb-card {
    border: 0;
    background: none;
}

.mobile-thumb-card .card-body {
    border-radius: 40px;
    background: #FDF3DC;
    border: 0;
    padding: 0;
    position: relative;
    text-align: center;
}

.mobile-thumb-card .lines-top:after {
    content: url("../assets/icons/icon-line-left-top-sm.svg");
    position: absolute;
    top: -20px;
    left: -30px;
}

.mobile-thumb-card .lines-bottom:after {
    content: url("../assets/icons/icon-line-right-bottom-sm.svg");
    position: absolute;
    bottom: -20px;
    right: -30px;
}

.mobile-thumb-card .card-img-top {
    border-radius: 40px 40px 0 0;
    width: auto;
    max-width: 100%;
}

.mobile-thumb-card .card-header {
    border: 0;
    background: none;
    text-align: center;
    color: #EF7056;
    font-family: 'Archivo-Light', Arial, Helvetica;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
    padding: 0;
}

.mobile-crd-text {
    margin-top: 70px;
}

.mobile-crd-text p {
    margin: 30px 0 0;
    color: #303838;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Archivo-Light', Arial, Helvetica;
}

.yellow-bg-nibble {
    padding: 55px 0 200px;
    background: linear-gradient(180deg, rgba(253, 243, 220, 1) 0%, rgba(253, 243, 220, 1) 70%, #FFFCF4 70%);
}

.arrow-tp a {
    position: relative;
}

.arrow-tp:before {
    content: url("../assets/icons/icon-arrow-top-left.svg");
}

.orange-txt {
    color: #EF7056;
}

.tex-big {
    font-size: 60px;
    line-height: normal;
}

.tex-big .sub {
    font-size: 60px;
    padding-left: 100px;
}

.muscle-container {
    padding: 60px 0;
}

.pad-0 {
    padding: 0 !important;
}

.amazing-text {
    position: relative;
    color: #EF7056;
    text-align: center;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 64px;
    line-height: 41px;
    filter: none;
    font-weight: 500;
}

.amazing-text:before {
    content: "amazing view";
    position: absolute;
    width: 130px;
    top: -80px;
    left: -70px;
    transform: translateX(180px) rotate(4deg);
    text-align: center;
    font-weight: 500;
}

.amazing-text video {
    height: 100%;
    translate: -160px;
}

.amazing-text div {
    width: 342px;
    border-radius: 40px;
    overflow: hidden;
    height: 300px;
    filter: drop-shadow(1px 3px 7px rgba(0, 0, 0, 0.30));
    margin: 0 auto;
}

.amazing-text:after {
    content: url("../assets/icons/icon-arrow-left-crv.svg");
    position: absolute;
    top: -30px;
    left: 50px;
}

.article-banner {
    padding: 40px 190px 0 40px;
    background: none;
    position: relative;
}

.article-banner .card-stack .stack-badge {
    position: static;
    margin-bottom: 20px;
    display: inline-block;
}

.article-banner .card-stack .stack-bt {
    position: static;
}

.article-banner .card-stack .card-img-overlay {
    padding: 135px 100px 0;
}

.profile-cont {
    font-family: 'Archivo-Light', Arial, Helvetica;
    font-size: 18px;
    line-height: 20px;
    color: #303838;
}

.profile-cont img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 50%;
    display: inline-block;
}

.profile-cont p {
    display: inline-block;
}

.date-cont {
    text-align: right;
    font-family: 'Archivo-Light', Arial, Helvetica;
    font-size: 18px;
    line-height: 32px;
    margin-right: 77px;
}


.article-sub-title {
    font-size: 35px;
    line-height: 42px;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    color: #303838;
    position: relative;
    padding: 10px;
    display: inline;
}

.article-sub-title::before {
    content: url("../assets/icons/quote-left.svg");
    position: absolute;
    left: -10px;
    top: -10px;
}

.article-sub-title::after {
    content: url("../assets/icons/quote-right.svg");
    position: absolute;
    bottom: 0px;
    padding-left: 5px;
}

.article-sub-p {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.article-sub-cont {
    padding: 50px 0;
}

.orange-btn-outline {
    border-radius: 40px;
    border: 1px solid #EF7056;
    color: #303838;
    text-align: center;
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
}

.msg-overlay {
    position: absolute;
    bottom: 30px;
    right: 40px;
    max-width: 350px;
}

.chat-bub-left {
    background: #fff;
    border-radius: 25px 25px 11px;
    padding: 10px 15px 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.right-tri:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -15px;
    bottom: 0;
    top: auto;
    border: 12px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #fff transparent transparent #fff;
}

.left-tri {
    border-radius: 25px 25px 25px 11px;
}

.left-tri:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -15px;
    right: auto;
    top: auto;
    bottom: 0;
    border: 12px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #fff #fff transparent transparent;
}



.chat-bub-left h4 {
    color: #EF7056;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 34px;
    margin: 0
}

.chat-bub-left p {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 25px;
    margin: 0
}

.w180p {
    width: 180px;
}

.msg-overlay .img-circl {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.article-sub-title-1 {
    font-size: 35px;
    line-height: 42px;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    color: #303838;
    position: relative;
    display: inline;
}

.article-sub-title-1::after {
    content: url("../assets/icons/icon-heart-line-1.svg");
    position: absolute;
    top: -20px;
    padding-left: 5px;
}

.article-caro-cont {
    padding: 140px 0;
}

.story-stack {
    width: 100% !important;
}

.stack-thumb {
    position: relative;
    margin-right: 0;
    margin-bottom: 0;
    width: 320px;
    width: 100%;
    border-radius: 40px;
    overflow: hidden;
    max-height: 340px;
}

.stack-thumb::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-image: linear-gradient(60deg, #303838 23.53%, rgba(48, 56, 56, 0.00) 92.9%);
    border-radius: 40px;
    overflow: hidden;
    z-index: 1;
}

.stack-thumb>img {
    margin: 0;
}

.stack-thumb .stack-badge {
    border-radius: 40px;
    background: #FDF3DC;
    color: #EF7056;
    text-align: center;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 18px;
    line-height: 20px;
    padding: 4px 10px;
    position: absolute;
    top: 40px;
    left: 30px;
}

.stack-thumb .card-title {
    color: #FFFCF4;
    font-family: 'Archivo', Arial, Helvetica;
    font-size: 40px;
    line-height: normal;
    margin-bottom: 10px;
    white-space: break-spaces;
}

.stack-thumb .figure-caption {
    color: #FDF3DC;
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 2;
}

.article-about-cont p {
    color: #303838;
    text-align: center;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.arrow-tp-r {
    position: relative;
}

.arrow-tp-r::after {
    content: url("../assets/icons/icon-arrow-top-right.svg");
    padding-left: 5px;
    top: -20px;
    position: absolute;
}

.sm-orange-line::after {
    content: url("../assets/line-organe-2.svg");
    left: 0;
    top: 40px;
    position: absolute;
}

.beyond-container {
    width: 670px;
}

.click-here-1 {
    position: relative;
    padding-bottom: 75px;
    display: inline-block;
}

.click-here-1 .click-pos {
    left: 100px;
    top: 100px;
}

.footer-banner {
    padding: 70px 70px 70px 360px;
    border-radius: 60em 0px 0px 60em;
    background: #FDF3DC;
    position: relative;
    margin-top: 160px;
}

.footer-banner>img {
    position: absolute;
    left: -155px;
    top: -100px;
}

.footer-banner h3 {
    color: #000;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    font-size: 35px;
    line-height: 42px;
    margin-bottom: 10px;
}

.footer-banner p {
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.pad-lef-15 {
    padding-left: 130px;
    padding-right: 0;
}

.art-text.line-3 {
    position: relative;
}

.art-text.line-3:after {
    content: url("../assets/line-orange-3.svg");
    right: -30px;
    bottom: 0;
    position: absolute;
}

.customer-cont {
    padding-top: 125px;
}


.contact-banner-cont {
    padding: 40px 0 40px 0;
    background: none;
}

.contact-ban-bor {
    padding: 40px 40px 40px 0;
    border-radius: 0 40px 40px 0;
    background: #FDF3DC;
}

.contact-banner {
    position: relative;
    border-radius: 0 40px 40px 0;
    background: url("../assets/HotelRoomViewWithWomanAndLuggage.png");
    background-size: cover;
}

.contact-banner>img {
    border-radius: 0px 40px 40px 0;
    width: 100%;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.35);
}

.contact-banner::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background-image: linear-gradient(-90deg, rgba(48, 56, 56, 0.00) 1.42%, rgba(48, 56, 56, 0.30) 36.14%, #303838 91.78%);
}

/*.contact-banner::after {content: url("../assets/icons/icon-arrow-left.svg");  right: -90px;  bottom: -100px;  position: absolute;}*/
.contact-banner .title-cont {
    position: relative;
    margin: 0;
    padding: 40px;
}

.contact-banner .tex-big .sub {
    padding-left: 0;
    color: #FDF3DC;
    text-transform: lowercase;
    font-size: 70px;
    padding: 8px 0;
}

.contact-banner .title-sub {
    padding-bottom: 10px;
}

.contact-banner p {
    color: #fff;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 28px;
    line-height: 37px;
    display: inline;
}

.form {
    margin-right: 40px;
    margin-top: 0;
    margin-left: 50px;
    position: relative;
}

.form:after {
    content: url("../assets/badge-hotel.svg");
    right: -40px;
    bottom: -110px;
    position: absolute;
}

.form .card {
    border-radius: 40px;
    background: #FDF3DC;
    /*box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.30);*/
    border: 0;
    padding: 40px;
}

.form .card-body {
    padding: 0;
}

/*.form:before{content: url("../assets/icons/icon-lines-top-right-sm.svg");position: absolute;top: -50px;right: -40px;}
.form:after{content: url("../assets/icons/icon-lines-bottom-right-sm.svg"); position: absolute; bottom: -50px;right: -30px;}
*/
.form-line {
    width: 100%;
    border: 0;
    background: none;
    color: #303838;
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 34px;
    border-bottom: solid 1px #EF7056;
    padding: 0;
    margin-bottom: 44px;
    transform: translateX(0) rotate(0.2deg)
}

.form-line:focus {
    border-color: #EF7056;
    border-width: 0 0 1px;
    outline: none;
}

.form-line::placeholder {
    color: #303838;
    opacity: 1;
}

.form-line::-ms-input-placeholder {
    color: #303838;
}

.form-line:focus::placeholder {
    color: #303838;
    opacity: 0.5;
}

.form-line:focus::-ms-input-placeholder {
    color: #303838;
}

.form-line::-ms-input-placeholder {
    color: #303838;
}

.form .btn-with-star::after {
    content: url("../assets/icons/icon-stars-orange.svg");
    position: absolute;
    top: 0px;
    right: -60px;
}

.shuffle-txt .art-text.confident {
    position: relative;
}

.shuffle-txt .art-text.confident::after {
    content: url("../assets/icons/icon-confidentiality.svg");
    position: absolute;
    top: 0px;
    right: -85px;
}

.video-container-sm {
    overflow: hidden;
    width: 100%;
    border-radius: 40px;
    height: 410px;
    position: relative;
}

.video-container-sm video {
    border-radius: 40px;
    height: 100%;
    translate: -160px;
}

.orangebt-line {
    position: relative;
}

.orangebt-line:after {
    content: url("../assets/icons/line-orange-2.svg");
    position: absolute;
    bottom: -30px;
    left: -30px;
}

.list-titl {
    text-transform: inherit;
    display: flex;
}

.list-titl .numb {
    color: #EF7056;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 64px;
    line-height: 41px;
    vertical-align: middle;
    font-weight: 500;
    text-transform: lowercase;
    padding-right: 25px;
}

.peacefully {
    position: relative;
}

.peacefully h4 {
    color: #EF7056;
    text-align: center;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 64px;
    line-height: 41px;
    display: inline;
    position: absolute;
    transform: translateX(0) rotate(-15deg);
    bottom: 60px;
    left: 90px;
}

.peacefully h4:after {
    content: url("../assets/icons/icon-lines-bottom-right-sm.svg");
    position: absolute;
    right: -30px;
    bottom: -40px;
}

.peacefully .video-container-sm {
    border-radius: 0 40px 40px 0;
}

.peacefully .video-container-sm video {
    border-radius: 0 40px 40px 0;
}

.story-bn-img,
.story-bn {
    border-radius: 40px;
    overflow: hidden;
    width: 100%;
}

.story-bn .hero-text h1.left-space::after {
    content: "horizons";
    font-size: 110px;
    line-height: 54.1px;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-weight: 500;
    color: #EF7056;
    position: absolute;
    padding-left: 30px;
}

.story-bn .hero-text h1 {
    font-size: 60px;
    line-height: normal;
}

.story-bn .hero-text h1 span.star::after {
    left: 9px;
    bottom: 3px;
    transform: scale(0.6)
}

.banner {
    padding: 40px;
    background: none;
    position: relative;
}

.story-card {
    border-radius: 28px;
    overflow: hidden;
    border: 0;
    background: #fff;
    box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.30);
    padding: 35px;
}

.story-card .card-body {
    padding: 0;
    min-height: 555px;
}

.story-card .card-title {
    color: #303838;
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
}

.story-card .card-footer {
    background: none;
    border: 0;
    padding: 25px 0 0;
}

.img-circl {
    border-radius: 50%;
}

.phone-bg-cont {
    background-image: url("../assets/phone-bg.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    padding: 90px 40px;
    background-attachment: fixed;
}

.half-curve-cont {
    border-radius: 50% 50% 0 0;
    background: #FDF3DC;
    padding-top: 130px;
    padding-bottom: 90px;
}

.line-sm {
    position: relative;
    padding-right: 145px;
}

.line-sm:after {
    content: url("../assets/line-orange-4.svg");
    left: -40px;
    top: 50px;
    position: absolute;
}

.title-sm {
    color: #303838;
    text-align: center;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 34px;
}

.orange-p {
    color: #EF7056;
    text-align: center;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.story-stack .figure-caption {
    left: 0;
    padding: 20px;
    bottom: 0;
    width: 100%;
}

.arrow-top-lg-r {
    position: relative;
}

.arrow-top-lg-r:after {
    content: url("../assets/arrow-right-lg.svg");
    position: absolute;
    right: -30px;
    bottom: -110px;
}


.circle {
    position: relative;
    padding-top: 20px;
}

.circle::after {
    content: url("../assets/circle-line.svg");
    left: 60px;
    top: -4px;
    position: absolute;
}

.sub-txt {
    color: #303838;
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 20px;
}

.quote {
    position: relative;
    padding: 0 15px;
}

.quote::before {
    content: url("../assets/icons/quote-left.svg");
    position: absolute;
    left: -10px;
    top: -10px;
}

.quote::after {
    content: url("../assets/icons/quote-right.svg");
    position: absolute;
    bottom: 0px;
    padding-left: 5px;
}

.line-wing {
    position: relative;
    margin-left: 290px;
    padding-right: 142px;
}

.line-wing:before {
    content: url("../assets/line-wing.svg");
    position: absolute;
    left: -140px;
    top: -20px;
    transform: scale(0.9);
}

.ambition-cont {
    padding: 40px 0;
}

.line-sm-1 {
    position: relative;
}

.line-sm-1::before {
    content: url("../assets/line-sm-1.svg");
    left: -40px;
    top: 30px;
    position: absolute;
}

.img-container-sm {
    overflow: hidden;
    width: 100%;
    border-radius: 40px;
    max-height: 300px;
    position: relative;
}

.border-rad-half {
    border-radius: 26px 0 0 26px;
}

.click-line {
    color: #EF7056;
    text-align: center;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 64px;
    line-height: 41px;
    transform: translateX(0) rotate(10deg);
    position: absolute;
    bottom: 70px;
    right: 45px;
    font-weight: 500;
}

.click-line:after {
    content: url("../assets/icons/icon-line-right-bottom-sm.svg");
    position: absolute;
    bottom: -20px;
    right: -30px;
}

.p-list {
    list-style: none;
    padding: 15px 0;
    margin: 0;
}

.p-list li {
    list-style-image: url("../assets/icons/icon-arrow-right-li.svg");
    padding: 0;
    margin: 0;
    color: #303838;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    list-style-position: inside;
}

.ms-50 {
    margin-left: 20%;
}

.left-arrow-line {
    position: relative;
}

.left-arrow-line:before {
    content: url("../assets/icons/icon-arrow-5.svg");
    position: absolute;
    bottom: -140px;
    left: -150px;
}

.env-arrow {
    position: relative;
}

.env-arrow:after {
    content: url("../assets/icons/bloc-env.svg");
    position: absolute;
    bottom: -30px;
    right: 0;
}

.icon-valid {
    position: relative;
}

.icon-valid:after {
    content: url("../assets/icons/icon-valid.svg");
}

.yellow-bg {
    padding: 55px 0 50px;
    background: #FDF3DC;
}

.has-arrow-2 {
    position: relative;
}

.has-arrow-2:after {
    content: url("../assets/icons/icon-arrow-4.svg");
    position: absolute;
    top: 10px;
    right: -120px;
}

.has-cloud {
    position: relative;
}

.has-cloud:after {
    content: url("../assets/icons/icon-line-cloud.svg");
    position: absolute;
    bottom: -60px;
    left: -60px;
}

.sticky-form {
    padding: 30px 25px !important;
    margin: 0;
    border-radius: 40px;
    background: #FDF3DC;
    box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.30);
}

.sticky-form:before,
.sticky-form:after {
    display: none !important;
}

.sticky-form .form-line {
    margin-bottom: 20px;
    border-width: 2px;
}

.btn-with-arrow {
    position: relative;
}

.btn-with-arrow:after {
    content: url("../assets/icons/icon-arrow-6.svg");
    position: absolute;
    top: 0px;
    right: -70px;
}

.curve-line {
    position: relative;
}

.curve-line:after {
    content: url("../assets/curve-orange-line.svg");
    position: absolute;
    bottom: -28px;
    right: -10px;
}

.line-sm-left {
    position: relative;
}

.line-sm-left:after {
    content: url("../assets/icon-orange-line-6.svg");
    position: absolute;
    bottom: -60px;
    left: -30px;
}

.text-red {
    text-decoration: underline;
    color: #EF7056 !important;
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
}

.banner-arrow {
    position: absolute;
    z-index: 100;
    bottom: 60px;
    left: 45%;
}

.half-video {
    border-radius: 0px 40px 40px 0px;
    filter: drop-shadow(1px 3px 7px rgba(0, 0, 0, 0.30));
    height: 350px;
    width: 180px;
    overflow: hidden;
}

.half-video video {
    height: 100%;
    translate: -160px;
}

.arrow-down-cv {
    position: relative;
}

.arrow-down-cv:after {
    content: url("../assets/icons/icon-arrow-down-curve.svg");
    position: absolute;
    top: -10px;
}

.story-stack video {
    height: 400px;
    display: flex;
    translate: -250px;
}

.form-line option {
    background: #FFFCF4;
    color: #303838;
    font-size: 20px;
    font-family: 'Archivo-Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    border-bottom: solid 1px #EF7056;
}

select.form-line {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: unset;
    width: 6%;
}

.carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="22" viewBox="0 0 48 22" fill="none"><g clip-path="url(%23clip0_820_82)"><path d="M46.9997 9.17285C29.705 13.1736 5.79541 10.3802 5.79541 10.3802" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/><path d="M16.5537 20.5775C11.7526 13.1738 5 10.3804 5 10.3804C10.3638 8.33861 16.5537 1 16.5537 1" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_820_82"><rect width="48" height="22" fill="white"/></clipPath></defs></svg>');
}

.carousel-control-next-icon {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="22" viewBox="0 0 48 22" fill="none"><g clip-path="url(%23clip0_820_86)"><path d="M1.00024 9.17285C18.2949 13.1736 42.2044 10.3802 42.2044 10.3802" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/><path d="M31.4463 20.5775C36.2474 13.1738 43 10.3804 43 10.3804C37.6362 8.33861 31.4463 1 31.4463 1" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_820_86"><rect width="48" height="22" fill="white"/></clipPath></defs></svg>');
}

.title-big {
    color: #303838;
    font-size: 60px;
    font-weight: 100;
    line-height: normal;
    font-family: 'Archivo-Thin', Arial, Helvetica, sans-serif;
    margin-bottom: 30px;
}

.title-big .art-text {
    color: #EF7056;
    text-align: center;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 110px;
    line-height: 54px;
    vertical-align: middle;
    padding-left: 10px;
    font-weight: 500;
    text-transform: lowercase;
}

.waiting-f-u {
    position: relative;
}

.waitng-txt {
    position: absolute;
    top: 80px;
    color: #EF7056;
    text-align: center;
    font-family: 'Autumn-Wind', Arial, Helvetica, sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    left: 60px;
    transform: translateX(0) rotate(-16deg);
}

.waitng-txt img {
    width: 25px;
    margin-left: 10px;
}

.orange-badge-outline {
    color: #303838;
    text-align: center;
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
    border-radius: 40px;
    border: 1px solid #EF7056;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    padding: 5px 8px;
    margin: 0 10px 0 0;
}

.line-5 {
    position: relative;
}

.line-5:after {
    content: url("../assets/line-orange-5.svg");
    position: absolute;
    bottom: -42px;
    right: -40px;
}

.medium-txt {
    font-family: 'Archivo-Medium', Arial, Helvetica, sans-serif;
}

.pr-100 {
    padding-right: 100px;
}

.testimonial-content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding: 5px;
}

.story-card {
    display: inline-block;
    width: 470px;
    margin-right: 34px;
    white-space: normal;
    vertical-align: top;
}



.space-holder {
    position: relative;
    width: 100%;
}

.sticky {
    position: sticky;
    top: 0;
    min-height: 770px;
    width: 100%;
}

.horizontal {
    position: absolute;
    height: 100%;
    will-change: transform;
    padding-top: 100px;
    padding-left: 50%;
}

.cards {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.sample-card {
    position: relative;
    height: 300px;
    width: 500px;
    background-color: #111f30;
    margin-right: 150px;
    flex-shrink: 0;
}

.second-footer a {
    color: #EF7056;
    text-decoration: none;
    padding-right: 20px;
}

.diamond-pos {
    position: relative;
}

.diamond-pos img {
    position: absolute;
    right: 0;
    width: 75px;
}

.review-box-cont {
    position: relative;
}

.source-txt {
    color: #FFF;
    text-align: right;
    font-family: 'Archivo-Light', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    position: absolute;
    right: 0;
    padding-top: 80px;
    margin: 0;
}

.dis-blk-mob {
    display: none;
}

.dis-none-mob {
    display: block;
}

.page-not-found {
    text-align: center;
    margin-top: 10%;
}

textarea.form-line {
    resize: none;
}

.contact-title {
    margin-bottom: 40px
}

.contact-title h1 {
    color: #EF7056;
    font-family: 'Archivo-Bold', Arial, Helvetica, sans-serif;
    font-size: 66px;
    font-weight: 800;
    letter-spacing: -1.32px;
    margin: 0;
}

.contact-title p {
    color: #EF7056;
    font-family: 'Archivo-Regular', Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 30px;
    margin: 0;
}

.contact-tab-cont {
    position: relative;
    padding: 65px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.contact-tab-cont:before {
    content: url("../assets/icons/icon-arrow-contact.svg");
    position: absolute;
    top: -20px;
    left: 200px;
}

.contact-tab-cont button {
    border-radius: 28px;
    background: rgba(255, 252, 244, 0.15);
    backdrop-filter: blur(6px);
    border: 4px solid rgba(255, 252, 244, 0.0);
    width: 250px;
    margin: 12px;
    height: 280px;
}

.contact-tab-cont button span {
    display: block;
    color: #EF7056;
    text-align: center;
    font-family: 'Archivo-Regular', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 50px;
}

.contact-tab-cont button img {
    max-height: 100px;
}

.contact-banner .title-sub>span {
    text-transform: none;
    font-size: 70px;
}

.contact-banner .title-sub span.art-text {
    font-size: 130px;
}

.contact-tab-cont button:hover {
    background: none;
    border: solid 4px #fff;
}

.contact-tab-cont button.active {
    background: none;
    border: solid 4px #EF7056;
}

.contact-btn {
    border-radius: 16px;
    border-bottom: 2px solid #EF7056;
    overflow: hidden;
}

.contact-btn:hover {
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.16);
    background: #EF7056;
    border-bottom: 2px solid #9D4B3A;
}

.contact-animate span {
    position: relative;
    animation: mymove 1s;
    animation-fill-mode: forwards;
}

.contact-animate img {
    position: relative;
    animation: mymove1 1s;
    animation-fill-mode: forwards;
}

@keyframes mymove {
    from {
        left: 0px;
    }

    to {
        left: 23px;
    }
}

@keyframes mymove1 {
    from {
        left: 0px;
    }

    to {
        left: 70px;
    }
}



/* accordian */
.subject-accrd .accordion-item {
    border: 0;
    background: none;
}

.subject-accrd .accordion-button {
    width: 100%;
    border: 0;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    background: none;
    color: #303838;
    font-family: 'Archivo', Arial, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 34px;
    border-bottom: solid 1px #EF7056;
    padding: 0;
    margin-bottom: 44px;
    transform: translateX(0) rotate(0.2deg);
    border-radius: 0 !important;
}

.subject-accrd .accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
    background-image: url('data:image/svg+xml,<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M1 1.25732C7.05079 4.98851 9.33374 10.2317 9.33374 10.2317C11.0024 6.06785 17 1.25732 17 1.25732" stroke="%23EF7056" stroke-width="2" stroke-miterlimit="10"/></svg>') !important;
}


.accordion-button::after {
    background-image: url('data:image/svg+xml,<svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M1 1.25732C7.05079 4.98851 9.33374 10.2317 9.33374 10.2317C11.0024 6.06785 17 1.25732 17 1.25732" stroke="%23EF7056" stroke-width="2" stroke-miterlimit="10"/></svg>') !important;
}

.subject-accrd .accordion-collapse {
    margin-top: -45px;
    margin-bottom: 44px;
}

.subject-accrd .accordion-body {
    padding: 0;
}

.subject-accrd .list-group-item {
    border-width: 0 0 1px 0;
    border-color: #EF7056 !important;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0;
    background: #FFFCF4;
}

.subject-accrd .list-group-item:last-child {
    border: 0;
}

.subject-accrd .list-group-item:hover {
    background: #EF7056;
    color: #fff;
}