.photo-wrapper .glider-contain .glider-next.disabled,
.thumbnails-wrapper .glider-contain .glider-next.disabled,
.photo-wrapper .glider-contain .glider-prev.disabled,
.thumbnails-wrapper .glider-contain .glider-prev.disabled {
    visibility: hidden;
}

.glider-track {
    width: 100% !important;
}

.App {
    font-family: sans-serif;
    position: relative;
}

.photos-gallery {
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    /* position: fixed; */
    width: 100%;
    height: 100%;
}

.glider {
    scrollbar-width: none;
}

.photo-wrapper {
    padding: 0px 30px;
    margin: 0px auto;
    height: 0vh;
    flex-grow: 1;
    width: calc(100% - 60px);
}

.photo-wrapper .glider-next,
.photo-wrapper .glider-prev {
    color: #666;
    /* THIS IS IMPORTANT! */
    /* Hover and focus can be in the same time, */
    /* after click arrow have :focus and :hover. */
    /* So the order of style is important. */
    /* Try, what happening when change order and use arrows ;
   ) */
}

.photo-wrapper .glider-next:focus,
.photo-wrapper .glider-prev:focus {
    /* Oryginal color, but on focus is is lighter */
    color: #666;
}

.photo-wrapper .glider-next:hover,
.photo-wrapper .glider-prev:hover {
    color: magenta;
}

.photo-wrapper .glider-contain {
    height: 100%;
}

.photo-wrapper .glider-contain .glider {
    height: 100%;
}

.photo-wrapper .glider-contain .glider .glider-track {
    height: 100%;
}

.photo-wrapper .slide__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.photo-wrapper .slide__content img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}

.thumbnails-wrapper {
    padding: 0;
    margin: 0px auto;
    max-width: 100%;
}

.thumbnails-wrapper .glider-next,
.thumbnails-wrapper .glider-prev {

    /* color: tomato; */
    color: transparent;
    font-size: 0px;
    /* THIS IS IMPORTANT! */
    /* Hover and focus can be in the same time, */
    /* after click arrow have :focus and :hover. */
    /* So the order of style is important. */
    /* Try, what happening when change order and use arrows ;
   ) */
}

.thumbnails-wrapper .glider-next:focus,
.thumbnails-wrapper .glider-prev:focus {
    /* Oryginal color, but on focus is is lighter */
    color: tomato;
}

.thumbnails-wrapper .glider-next:hover,
.thumbnails-wrapper .glider-prev:hover {
    color: magenta;
}

.thumbnails-wrapper .glider-slide+.glider-slide .slide__content {
    /* margin-right: 15px;
    margin-left: 15px; */

}

.thumbnails-wrapper .slide__content {
    display: flex;

    max-width: 100%;
    justify-content: center;
    align-items: center;

    border-radius: 40px;
    overflow: hidden;
    margin: 0 15px;
}

.thumbnails-wrapper .slide__content.--current-selected {
    /* border: 3px solid magenta; */
}

.thumbnails-wrapper .slide__content>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.glider-prev,
.glider-next {
    font-size: 0;
    color: transparent;
}

/*IMP IMAGE GLIDER*/
.glider-prev {
    top: 50% !important;
    left: 30px !important;
    /* background-image: url('data:image/svg+xml,<svg width="48" height="22" viewBox="0 0 48 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.9998 9.1731C29.7051 13.1738 5.79553 10.3804 5.79553 10.3804" stroke="%23EF7056" stroke-width="3" stroke-miterlimit="10"/><path d="M16.5537 20.5775C11.7526 13.1738 5 10.3804 5 10.3804C10.3638 8.33861 16.5537 1 16.5537 1" stroke="%23EF7056" stroke-width="3" stroke-miterlimit="10"/></svg>') !important; */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="22" viewBox="0 0 48 22" fill="none"><g clip-path="url(%23clip0_820_82)"><path d="M46.9997 9.17285C29.705 13.1736 5.79541 10.3802 5.79541 10.3802" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/><path d="M16.5537 20.5775C11.7526 13.1738 5 10.3804 5 10.3804C10.3638 8.33861 16.5537 1 16.5537 1" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_820_82"><rect width="48" height="22" fill="white"/></clipPath></defs></svg>') !important;
    height: 23px;
    width: 50px;
}


.glider-next {
    top: 50% !important;
    right: 30px !important;
    /* background-image: url('data:image/svg+xml,<svg width="48" height="22" viewBox="0 0 48 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.00022 9.1731C18.2949 13.1738 42.2044 10.3804 42.2044 10.3804" stroke="%23EF7056" stroke-width="3" stroke-miterlimit="10"/><path d="M31.4463 20.5775C36.2474 13.1738 43 10.3804 43 10.3804C37.6362 8.33861 31.4463 1 31.4463 1" stroke="%23EF7056" stroke-width="3" stroke-miterlimit="10"/></svg>') !important; */
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="22" viewBox="0 0 48 22" fill="none"><g clip-path="url(%23clip0_820_86)"><path d="M1.00024 9.17285C18.2949 13.1736 42.2044 10.3802 42.2044 10.3802" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/><path d="M31.4463 20.5775C36.2474 13.1738 43 10.3804 43 10.3804C37.6362 8.33861 31.4463 1 31.4463 1" stroke="%23FDF3DC" stroke-width="3" stroke-miterlimit="10"/></g><defs><clipPath id="clip0_820_86"><rect width="48" height="22" fill="white"/></clipPath></defs></svg>') !important;
    height: 23px;
    width: 50px;
}

.glider .glider-track .glider-slide {
    min-width: 290px !important;
}

.glider-text-align-left {
    text-align: left;
}

/*IMP IMAGE GLIDER- ends here*/